import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, Section, Image, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdArrowDownward } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Ev | Habitat Harmony Interiors
			</title>
			<meta name={"description"} content={"Mekanları kişiliğinizin ve yaşam tarzınızın yansımalarına dönüştürdüğümüz yer. "} />
			<meta property={"og:title"} content={"Ev | Habitat Harmony Interiors"} />
			<meta property={"og:description"} content={"Mekanları kişiliğinizin ve yaşam tarzınızın yansımalarına dönüştürdüğümüz yer. "} />
			<meta property={"og:image"} content={"https://glory-troly.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://glory-troly.com/img/4635381.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://glory-troly.com/img/4635381.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://glory-troly.com/img/4635381.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://glory-troly.com/img/4635381.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://glory-troly.com/img/4635381.png"} />
			<meta name={"msapplication-TileImage"} content={"https://glory-troly.com/img/4635381.png"} />
		</Helmet>
		<Components.Header />
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://glory-troly.com/img/1.jpg) center/cover"
			padding="60px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Box margin="-16px -16px -16px -16px" display="flex">
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="75%"
					md-width="100%"
					margin="0px 0px 200px 0px"
				>
					<Box display="flex" flex-direction="column">
						<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
							Habitat Harmony Interiors
						</Text>
						<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0">
							Habitat Harmony Interiors'a Hoş Geldiniz
						</Text>
					</Box>
				</Box>
			</Box>
			<Box text-align="center" margin="96px 0 0 0">
				<Text margin="8px 0" text-transform="uppercase">
					Biz Kimiz
				</Text>
				<Icon category="md" margin="0 auto" icon={MdArrowDownward} />
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" lg-padding="56px 0 56px 0" sm-padding="32px 0 32px 0" background="--color-light">
			<Box
				margin="0px 0px 32px 0px"
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 33px 0px"
				display="flex"
				flex-direction="column"
				align-items="center"
				lg-padding="15px 15px 15px 15px"
				sm-margin="0px 0px 10px 0px"
				justify-content="center"
			>
				<Text
					font="--headline2"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Neden Habitat Harmony Interiors'ı Seçmelisiniz?
				</Text>
				<Text font="--lead" color="--darkL2" text-align="center" md-width="100%">
					Mekanları kişiliğinizin ve yaşam tarzınızın yansımalarına dönüştürdüğümüz yer. Daire ve ev tasarımında uzmanlaşan yaklaşımımız, estetik zarafeti işlevsel yaşamla birleştirerek alanınızın her köşesinin yalnızca tasarlanmasını değil, aynı zamanda özenle küratörlüğünü yapmasını sağlar.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-wrap="wrap"
				width="100%"
				margin="0px 0px 16px 0px"
				justify-content="flex-start"
				md-margin="0px 0px 16px 0px"
			>
				<Box
					width="100%"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					md-width="100%"
					lg-width="100%"
				>
					<Image
						src="https://glory-troly.com/img/2.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="522px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						object-position="0% 30%"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
						Kişiye Özel Tasarım Çözümleri{" "}
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
						Her mekanın kendine özgü bir hikayesi olduğuna inanıyoruz. Tasarımlarımız, benzersiz zevkinizi, ihtiyaçlarınızı ve hayatınızı yaşama şeklinizi yansıtacak şekilde özelleştirilir.
					</Text>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
						Kalite ve İşçilik
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
						Yetenekli zanaatkarlarla işbirliği yapıyor ve mekanınızın her ayrıntısının mükemmel bir şekilde uygulanmasını sağlamak için yüksek kaliteli malzemeler kullanıyoruz.
					</Text>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
						Stressiz Süreç
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
						İlk konsültasyondan nihai görünüme kadar aerodinamik tasarım sürecimiz, tasarım yolculuğunuzu keyifli bir deneyim haline getirerek son derece profesyonel bir şekilde yönetilir.
					</Text>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
						Bütünsel Yaklaşım
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
						Ekibimiz, yerleşim planlaması ve renk şemalarından mobilya seçimi ve aydınlatmaya kadar iç tasarımın her yönünü göz önünde bulundurarak uyumlu ve ahenkli bir alan yaratır.
					</Text>
				</Box>
			</LinkBox>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-12">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="100%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					position="relative"
					padding="0px 0px 70% 0px"
				>
					<Image
						width="100%"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
						src="https://glory-troly.com/img/3.jpg"
						object-fit="cover"
						right={0}
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				width="100%"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Ek Avantajlar
				</Text>
			</Box>
			<Box
				flex-direction="column"
				padding="16px 16px 16px 16px"
				display="flex"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				width="33.3333%"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--grey"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Sürdürülebilir Tasarım Seçenekleri - Sürdürülebilirlik konusunda kararlıyız. Daha yeşil bir yaşam tarzına uygun çevre dostu tasarım seçenekleri hakkında bize danışın.
				</Text>
			</Box>
			<Box
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				width="33.3333%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				lg-padding="0px 16px 16px 16px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--grey"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Alan Optimizasyonu - Tasarımlarımız alanı akıllıca kullanarak en kompakt alanlarda bile işlevsellik sağlar.
				</Text>
			</Box>
			<Box
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				display="flex"
				width="33.3333%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 60% 0px"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					width="100%"
					height="auto"
					overflow-y="hidden"
				>
					<Image
						bottom="0px"
						min-height="100%"
						src="https://glory-troly.com/img/4.jpg"
						display="block"
						width="100%"
						left={0}
						right={0}
						object-fit="cover"
						position="absolute"
						top="auto"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});